import React from 'react';
import { Form, Input, Button } from 'antd';

const RegistrationPage: React.FC = () => {
    const [form] = Form.useForm();

    const handleFinish = (values: any) => {
        fetch('/api/reg', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
        })
            .then((response) => {
                if (response.ok) {
                    alert('Регистрация успешна!');
                } else {
                    alert('Ошибка регистрации.');
                }
            })
            .catch(() => alert('Ошибка сети.'));
    };

    return (
        <div style={{ maxWidth: 400, margin: '0 auto', padding: '20px' }}>
            <h1>Регистрация</h1>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                initialValues={{ email: '', password: '', confirmPassword: '' }}
            >
                <Form.Item
                    label="Электронная почта"
                    name="email"
                    rules={[{ required: true, message: 'Пожалуйста, введите вашу почту' }]}
                >
                    <Input placeholder="Введите почту" />
                </Form.Item>
                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
                >
                    <Input.Password placeholder="Введите пароль" />
                </Form.Item>
                <Form.Item
                    label="Подтвердите пароль"
                    name="confirmPassword"
                    dependencies={['password']}
                    rules={[
                        { required: true, message: 'Пожалуйста, подтвердите пароль' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Пароли не совпадают!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Повторите пароль" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Зарегистрироваться
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default RegistrationPage;

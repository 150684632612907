import {Flex, Typography} from "antd";
import React from "react";

const PageNotFound = () => {
    return <Flex justify="center" align="center">
        <Typography.Title level={1} color="textSecondary">
            Страница не найдена
        </Typography.Title>
    </Flex>
}

export default PageNotFound
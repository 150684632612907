import React, {useEffect, useRef, useState} from 'react';
import {Form, Input, Tag, Typography, Upload, Button, Spin, message, Flex} from 'antd';
import styled from '@emotion/styled';
import {CloseCircleTwoTone, PlusOutlined, UploadOutlined} from '@ant-design/icons';
import axios from 'axios';
import useFields from "../hooks/useFields";
import {PlayCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {Fields} from "./fields/types";
import fieldsMatcher from "./fields";
import {Modal} from 'antd';

import fields from "./fields";
import {HEADER_HEIGHT} from "../constants";

// TODO отправлять на сервер все поля, доступные на клиенте

type RequiredMark = boolean | 'optional' | 'customize';

const customizeRequiredMark = (label: React.ReactNode, {required}: { required: boolean }) => (
    <>
        {required ? <Tag color="error">Required</Tag> : <Tag color="warning">optional</Tag>}
        {label}
    </>
);


const PdfUploadContainer = styled.div`
    border: 2px dashed #1677ff;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
`;

const PdfUploadDescription = styled.p`
    color: #333;
    font-size: 14px;
    margin-bottom: 16px;
`;


const StyledFormItemsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 2vw;
    align-items: center;

    > * {
        align-self: center;
    }

    @media (max-width: 1200px) {
        grid-template-columns: 1fr; /* Одна колонка на экранах шириной до 1200px */
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr; /* Одна колонка на экранах шириной до 768px */
        gap: 20px; /* Увеличим отступы для мобильных */
    }
`;


const StyledFormContainer = styled.div`
    padding: 3vw;
    position: relative;

    @media (max-width: 1200px) {
        padding: 2vw; /* Уменьшаем отступы на экранах до 1200px */
    }

    @media (max-width: 768px) {
        padding: 1vw; /* Еще меньше отступов на мобильных устройствах */
    }
`;


const StyledButton = styled(Button)`
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
`;

const SpinnerOverlay = styled.div`
    display: ${({isLoading}: { isLoading: boolean }) => (isLoading ? 'flex' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const downloadPDF = async (htmlContent: string) => {
    try {
        const response = await fetch('/api/makepdf', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({html: htmlContent}),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'out.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
};


const downloadDOCX = async (htmlContent: string) => {
    try {
        const response = await fetch('/api/makedocx', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({html: htmlContent}),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'out.docx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
};

const staticFields = ["jobTitle",
    "firstName",
    "email",
    "country",
    "lastName",
    "phone",
    "city",
    "description"]

const MainForm: React.FC<{ setResult: React.Dispatch<React.SetStateAction<string>> }> = ({setResult}) => {
    const [promptValue, setPromptValue] = useState('');
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingParseResume, setIsLoadingParseResume] = useState<"pending" | "finished" | "error" | null>(null);
    const [photo, setPhoto] = useState<any>("");
    const [pdf, setPdf] = useState<any>("");
    const [htmlContent, setHtmlContent] = useState("");
    const [isStartupPageVisible, setIsStartupPageVisible] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showFullGenModal, setShowFullGenModal] = useState(false);

    const FieldsToolbar = ({fieldName}: { fieldName: string }) => {
        return <>
            <Button
                type="link"
                icon={<PlayCircleOutlined/>}
                onClick={() => handleFieldSubmit(fieldName, promptValue)}
                style={{}}
            />
            <Button
                type="link"
                icon={<QuestionCircleOutlined/>}
                onClick={handleOpenPromptPopup}
            />
        </>
    }

    const {components, toolbar, addField, addManyFields, fieldsOnForm} = useFields([], FieldsToolbar)
    const [professionalSummary, setProfessionalSummary] = useState<string>('');
    const [parserResult, setParserResult] = useState({})

    const onRequiredTypeChange = ({requiredMarkValue}: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    const [isPromptPopupOpen, setIsPromptPopupOpen] = useState(false);

    const handleOpenPromptPopup = () => {
        setIsPromptPopupOpen(true);
    };

    const handleClosePromptPopup = () => {
        setIsPromptPopupOpen(false);
    };

    const handleStartupSubmit = async (url: string) => {
        setIsSubmitting(true);
        try {
            setIsLoading(true);
            setIsLoadingParseResume("pending");

            const textData = form.getFieldValue("startupText");
            await form.validateFields();

            const fields = [...Object.keys(fieldsMatcher), ...staticFields];

            const formData = new FormData();
            formData.append("text", textData);
            formData.append("fields[]", JSON.stringify(fields));

            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const filledFields = response.data;

            const {description, ...others} = filledFields
            setProfessionalSummary(description);
            const dynamicFields = Object.keys(others).filter((field) => {
                if (!staticFields.includes(field)) return field;
            })

            addManyFields(dynamicFields as Fields[])

            setParserResult(filledFields)
            setIsLoadingParseResume("finished");

            message.success("Text data processed successfully!");
            setIsStartupPageVisible(false);
        } catch (error) {
            console.error("Error processing text data:", error);
            message.error("Failed to process text data.");
            setIsLoadingParseResume("error");
        } finally {
            setIsSubmitting(false);
            setIsLoading(false);
        }
    };

    const handleFieldSubmit = async (fieldName: string, promptValue: string = '') => {
        try {
            const fieldValue = form.getFieldValue(fieldName);
            if (!fieldValue) {
                message.error(`Field ${fieldName} is empty!`);
                return;
            }

            setIsLoading(true);

            const formattedData = {
                messages: [{message: JSON.stringify({[fieldName]: fieldValue, prompt: promptValue})}]
            };

            const res = await axios.post('/api/chatfield', formattedData, {
                headers: {'Content-Type': 'application/json'}
            });

            const result = res.data;

            if (fieldName === 'description') {
                setProfessionalSummary(result);
            } else {
                form.setFieldsValue({[fieldName]: result});
            }

            message.success(`Field ${fieldName} updated successfully!`);
        } catch (error) {
            console.error("Error submitting field:", error);
            message.error("Failed to submit field.");
        } finally {
            setIsLoading(false);
        }
    };


    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const values = await form.validateFields();

            const formattedData = {
                model: "mistral:7b",
                messages: [{
                    message: JSON.stringify(values)
                }]
            };

            console.log("Submitting data:", JSON.stringify(formattedData));

            const res = await axios.post('/api/chat', formattedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const htmlResult = res.data;
            setResult(htmlResult);
            setHtmlContent(htmlResult);

            console.log(res);

            message.success('Form submitted successfully!');
        } catch (error: any) {
            console.error("Submission error:", error);
            if (axios.isAxiosError(error)) {
                console.error("Axios error details:", error.response?.data);
                message.error(`Form submission failed! ${error.response?.data?.message || error.message}`);
            } else {
                message.error(`Form submission failed! ${error.message}`);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handlePhotoChange = ({fileList}: any) => {
        if (fileList.length > 0)
            setPhoto(fileList[0]);
    };
    const handlePdfChange = ({fileList}: any) => {
        if (fileList.length > 0)
            setPdf(fileList[0]);
    };

    const handlePdfUpload = async () => {
        if (pdf.length === 0) {
            message.error("Please select a PDF file first!");
            return;
        }

        const formData = new FormData();
        formData.append("file", pdf.originFileObj);
        formData.append("fields[]", JSON.stringify([...Object.keys(fieldsMatcher), ...staticFields]));

        try {
            setIsLoading(true);
            setIsLoadingParseResume("pending");

            const response = await axios.post('/api/parseResume', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const filledFields = response.data

            const {description, ...others} = filledFields
            setProfessionalSummary(description);
            const dynamicFields = Object.keys(others).filter((field) => {
                if (!staticFields.includes(field)) return field;
            })

            addManyFields(dynamicFields as Fields[])

            setParserResult(filledFields)
            message.success("PDF uploaded and parsed successfully!");
        } catch (error) {
            console.error("Error uploading PDF:", error);
            message.error("Failed to upload and parse the PDF.");
        } finally {
            setIsLoadingParseResume("finished");
        }
    };

    useEffect(() => {
        if (isLoadingParseResume === "finished") {
            form.setFieldsValue(parserResult);
            setIsLoading(false);
            setIsLoadingParseResume(null)
        }
    }, [isLoadingParseResume, parserResult])

    if (isStartupPageVisible) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: `calc(100vh - ${HEADER_HEIGHT}px)`,
                width: "100dvw",
                padding: "20px",
                position: "absolute",
                zIndex: 10,
                backgroundColor: "white",
                boxSizing: "border-box"
            }}>
                {isSubmitting && (
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000
                    }}>
                        <Spin size="large"/>
                    </div>
                )}

                <Form
                    form={form}
                    layout="vertical"
                    style={{width: "100%", maxWidth: "600px"}}
                >
                    <Form.Item
                        name="startupText"
                        rules={[{
                            required: true,
                            message: "Please enter text about yourself."
                        }]} // Проверка на заполнение
                    >
                        <Input.TextArea
                            placeholder="Enter any information about yourself, including work experience, hobbies, and anything else that might help create a resume."
                            style={{fontSize: "16px"}}
                            rows={8}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Flex justify={"space-between"} wrap={"wrap"} style={{marginBottom: 20, width: "100%"}}>
                        <StyledButton
                            type="primary"
                            style={{backgroundColor: "#1677ff", color: "white"}}
                            onClick={()=>handleStartupSubmit("/api/parseResume")}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <Spin size="small"/> : "Submit"}
                        </StyledButton>
                        <Button onClick={() => setShowFullGenModal(true)}>Magic Generation</Button>
                        <Modal
                            open={showFullGenModal}
                            onOk={()=>handleStartupSubmit("/api/parseRandResume")}
                            onCancel={() => setShowFullGenModal(false)}
                        >
                            <Typography>При недостатке данных могут быть сгенерированы ложные данные. Будьте внимательны.</Typography>
                        </Modal>
                        </Flex>

                    </Form.Item>
                    <Button
                        type="link"
                        style={{position: "absolute", top: 10, right: 10, fontSize: "18px", color: "#1677ff"}}
                        onClick={() => setIsStartupPageVisible(false)}
                    >
                        <CloseCircleTwoTone />
                    </Button>
                    <PdfUploadContainer>
                        <PdfUploadDescription>
                            Upload your resume to automatically fill the form fields.
                        </PdfUploadDescription>

                        <Form.Item valuePropName="fileList"
                                   getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}>
                            <Upload
                                accept=".pdf"
                                fileList={pdf ? [pdf] : []}
                                onChange={handlePdfChange}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined/>}>Select PDF</Button>
                            </Upload>
                        </Form.Item>

                        <StyledButton type="default" onClick={handlePdfUpload} disabled={!pdf}>
                            Upload PDF
                        </StyledButton>
                    </PdfUploadContainer>
                </Form>
            </div>
        );
    }


    return (
        <StyledFormContainer>
            <SpinnerOverlay isLoading={isLoading}>
                <Spin size="large"/>
            </SpinnerOverlay>
            <Typography.Title level={3}>Personal Details</Typography.Title>
            <Form
                form={form}
                layout="vertical"
                initialValues={{requiredMarkValue: 'optional'}}
                onValuesChange={onRequiredTypeChange}
                requiredMark={requiredMark === 'customize' ? customizeRequiredMark : requiredMark}
            >
                <StyledFormItemsContainer>

                    <Form.Item label="Wanted Job Title" tooltip="Some text" name="jobTitle" rules={[{required: true}]}>
                        <Input
                            placeholder="e.g. Teacher"
                            addonAfter={<FieldsToolbar fieldName={"jobTitle"}/>}
                        />
                    </Form.Item>


                    <Form.Item label="Upload photo" valuePropName="fileList"
                               getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}>
                        <Upload
                            // action="/upload.do"
                            listType="picture-card"
                            fileList={photo ? [photo] : []}
                            onChange={handlePhotoChange}
                            maxCount={1}
                        >
                            {photo.length >= 1 ? null : (
                                <div>
                                    <PlusOutlined/>
                                    <div style={{marginTop: 8}}>Upload</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>

                    <Form.Item label="First Name" name="firstName" rules={[{required: true}]}>
                        <Input
                            placeholder="John Doe"
                            addonAfter={<FieldsToolbar fieldName={"firstName"}/>}
                        />
                        <Modal
                            title="Уточните промпт"
                            open={isPromptPopupOpen}
                            onOk={handleClosePromptPopup}
                            onCancel={handleClosePromptPopup}
                        >
                            <Input
                                value={promptValue}
                                onChange={(e) => setPromptValue(e.target.value)}
                                placeholder="Введите уточнение"
                            />
                        </Modal>
                    </Form.Item>

                    <Form.Item label="Email" name="email" rules={[{required: true, type: 'email'}]}>
                        <Input
                            placeholder="example@exmail.com"
                            addonAfter={<FieldsToolbar fieldName={"email"}/>}
                        />
                    </Form.Item>

                    <Form.Item label="Country" name="country" rules={[{required: true}]}>
                        <Input
                            placeholder="e.g. United States"
                            addonAfter={<FieldsToolbar fieldName={"country"}/>}
                        />
                    </Form.Item>
                    <Form.Item label="Last Name" name="lastName" rules={[{required: true}]}>
                        <Input
                            addonAfter={<FieldsToolbar fieldName={"lastName"}/>}
                        />
                    </Form.Item>

                    <Form.Item label="Phone" name="phone" rules={[{required: true}]}>
                        <Input
                            addonAfter={<FieldsToolbar fieldName={"phone"}/>}
                        />
                    </Form.Item>

                    <Form.Item label="City" name="city" rules={[{required: true}]}>
                        <Input
                            addonAfter={<FieldsToolbar fieldName={"city"}/>}
                        />
                    </Form.Item>

                    {components}
                </StyledFormItemsContainer>
                <Typography.Title level={3}>Professional Summary</Typography.Title>

                <Form.Item label="Professional Summary" name="description" rules={[{required: true}]}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Input.TextArea
                            placeholder="Write your professional summary here..."
                            autoSize={{minRows: 4, maxRows: 8}}
                            style={{flex: 1, marginRight: '8px'}}
                            value={professionalSummary} // Привязка состояния к полю
                            onChange={(e) => setProfessionalSummary(e.target.value)} // Обновление состояния при вводе
                        />
                        <FieldsToolbar fieldName={"description"}/>
                    </div>
                    <Modal
                        title="Уточните промпт"
                        open={isPromptPopupOpen}
                        onOk={handleClosePromptPopup}
                        onCancel={handleClosePromptPopup}
                    >
                        <Input
                            value={promptValue}
                            onChange={(e) => setPromptValue(e.target.value)}
                            placeholder="Введите уточнение"
                        />
                    </Modal>
                </Form.Item>

                <Flex justify={"space-between"} wrap={"wrap"} style={{marginBottom: 20, width: "100%"}}>
                    <StyledButton type="primary" onClick={handleSubmit}>
                        Submit
                    </StyledButton>
                    <StyledButton type="primary" onClick={() => downloadPDF(htmlContent)}>
                        Download PDF
                    </StyledButton>

                    <StyledButton type="primary" onClick={() => downloadDOCX(htmlContent)}>
                        Download DOCX
                    </StyledButton>

                </Flex>

                <Button
                    type="link"
                    style={{position: "absolute", top: 10, right: 10, fontSize: "18px", color: "#1677ff"}}
                    onClick={() => setIsStartupPageVisible(true)}
                >
                    <CloseCircleTwoTone />
                </Button>

            </Form>
            {toolbar}

        </StyledFormContainer>
    );
};

export default MainForm;



import React from 'react';
import { Form, Input, Button } from 'antd';

const LoginPage: React.FC = () => {
    const [form] = Form.useForm();

    const handleFinish = (values: any) => {
        fetch('/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
        })
            .then((response) => {
                if (response.ok) {
                    alert('Успешная авторизация!');
                } else {
                    alert('Ошибка авторизации.');
                }
            })
            .catch(() => alert('Ошибка сети.'));
    };

    return (
        <div style={{ maxWidth: 400, margin: '0 auto', padding: '20px' }}>
            <h1>Вход</h1>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                initialValues={{ email: '', password: '', confirmPassword: '' }}
            >
                <Form.Item
                    label="Электронная почта"
                    name="email"
                    rules={[{ required: true, message: 'Пожалуйста, введите вашу почту' }]}
                >
                    <Input placeholder="Введите почту" />
                </Form.Item>
                <Form.Item
                    label="Пароль"
                    name="password"
                    rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
                >
                    <Input.Password placeholder="Введите пароль" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Войти
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default LoginPage;

import styled from "@emotion/styled";
import { Button, Flex } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import {HEADER_HEIGHT} from "../constants";

const StyledHeader = styled.header`
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.07);
    height: ${HEADER_HEIGHT}px;
    box-sizing: border-box;
    width: 100%;
    z-index: 10;
`

const StyledChildren = styled.div`
    padding-top: ${HEADER_HEIGHT}px;
    position: absolute;
    z-index: 1;
    width: 100%;
`

const Header = () => {
    const navigate  = useNavigate()

    return <StyledHeader>
        <Button onClick={() => {
            navigate("/")
        }}>Home</Button>
        <Flex gap={10} justify={"end"} style={{width:'100%'}}>
        <Button onClick={() => {
            navigate("/login")
        }}>Login</Button>
        <Button onClick={() => {
            navigate("/reg")
        }}>Sing up</Button>
        </Flex>
    </StyledHeader>
}

type PageLayoutProps = {children: React.ReactNode}

const PageLayout = ({children}: PageLayoutProps) =>{
    return <div>
        <Header />
        <StyledChildren>{children}</StyledChildren>
    </div>
}

export default PageLayout
import React, {useState} from "react";
import MainPageLayout from "../components/MainPageLayout";
import MainForm from "../components/MainForm";
import Result from "../components/Result";

const MainPage = () => {
    const [result, setResult] = useState("");

    return <MainPageLayout>
        <MainForm setResult={setResult}/>
        <Result value={result}/>
    </MainPageLayout>
}

export default MainPage;

import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router';
import RegistrationPage from "../pages/RegistrationPage";
import PageNotFound from '../pages/PageNotFound';
import LoginPage from "../pages/LoginPage";
import MainPage from "../pages/MainPage";
import PageLayout from "./PageLayout";

const routes = [
    {
        path: "/",
        Component: MainPage
    },
    {
        path: "/reg",
        Component: RegistrationPage
    },
    {
        path: "/login",
        Component: LoginPage
    }
]

const App: React.FC = () => {

    return (
        <BrowserRouter>
            <Routes>
                {routes.map(route => {
                    const {Component} = route;

                    return <Route path={route.path} element={<PageLayout><Component /></PageLayout>}/>
                })}
                <Route
                    path="*"
                    element={<PageNotFound />}
                />
            </Routes>
        </BrowserRouter>
    );
};


export default App;
